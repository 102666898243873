import { Redirect } from "@shopify/app-bridge/actions";
import { frontEndRedirect, frontEndTabOpen } from "@smartrr/shared/utils/locationUtils";

import { getAppBridgeInstance } from "./getAppBridgeInstance";
import { isInIFrame } from "./isInIframe";

export async function vendorPortalFrontendRedirect(
  fullRedirectUrl: string,
  openInNewTabIfNotIframe = false
): Promise<void> {
  if (isInIFrame) {
    Redirect.create(await getAppBridgeInstance()).dispatch(Redirect.Action.REMOTE, fullRedirectUrl);
  } else if (openInNewTabIfNotIframe) {
    frontEndTabOpen(fullRedirectUrl);
  } else {
    frontEndRedirect(fullRedirectUrl);
  }
}
