import { getSmartrrShopifyAuthenticationRedirectUrl } from "@smartrr/shared/utils/getShopifyAppUrl";

import { isInIFrame } from "../isInIframe";
import { vendorPortalFrontendRedirect } from "../vendorPortalFrontendRedirect";

// used to log into a different store than the one presently logged into

export async function redirectToShopifyAuth(shopUrl: string, openInNewTabIfNotIframe = false): Promise<void> {
  return vendorPortalFrontendRedirect(
    getSmartrrShopifyAuthenticationRedirectUrl(shopUrl, isInIFrame),
    openInNewTabIfNotIframe
  );
}
