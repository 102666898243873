import { stringify } from "qs";
import { getParsedQuery } from "./frontendQueryUtils";
import { Primitive } from "./PrimitiveKeys";
import {
  adminRoutePrefix,
  appPrefixedHost,
  fromShopifyAppPropertyName,
  shopUrlPropertyName,
  vendorApiRoutePrefix,
} from "../constants";

function getShopifyAppBaseUrl(shopUrl: string, apiKey: string) {
  return `https://${shopUrl}/admin/apps/${apiKey}`;
}

export function getShopifyIframeAppStartingUrl(
  queryObj: { [key: string]: Primitive } = {},
  shopUrl: string,
  apiKey: string
) {
  return `${getShopifyAppBaseUrl(shopUrl, apiKey)}${adminRoutePrefix}${stringify(queryObj, {
    addQueryPrefix: true,
  })}`;
}

export function getSmartrrShopifyAuthenticationRedirectUrl(shopUrl: string, isInIFrame?: boolean) {
  const queryString = stringify(
    {
      ...(getParsedQuery()[fromShopifyAppPropertyName] || isInIFrame
        ? { [fromShopifyAppPropertyName]: true }
        : {}),
      ...(shopUrl ? { [shopUrlPropertyName]: shopUrl } : {}),
    },
    { addQueryPrefix: true }
  );

  return `https://${appPrefixedHost}${vendorApiRoutePrefix}/auth/shopify${queryString}`;
}
